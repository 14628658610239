import {graphql} from "gatsby";
import {Helmet, Trans} from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import Layout from "../../../components/nigeria/layout";
import {Nigeria_MetaTags} from "../../../helpers/constants";
import {ArrowRightShortIcon,} from "../../../images/nigeria/Icons";

import RequestWidget from "../../../images/nigeria/personal/salary-advance/request.svg";
import SafetyWidget from "../../../images/nigeria/personal/salary-advance/safety.svg";
import RepaymentWidget from "../../../images/nigeria/personal/salary-advance/repayment.svg";


import HeroImage from "../../../images/nigeria/personal/salary-advance/salary-hero.png";
import HeroImageMobile from "../../../images/nigeria/personal/salary-advance/salary-hero-mobile.png";
import mobileVector from "../../../images/nigeria/personal/salary-advance/mobile-bg-usecases.svg";
import vector from "../../../images/nigeria/personal/salary-advance/Vector.svg";
import useCasesBG from "../../../images/nigeria/personal/salary-advance/useCaseBG.png";
import papieesMeatro from "../../../images/nigeria/personal/papieesMeatro.png";
import thePlace from "../../../images/nigeria/personal/thePlace.png";
import kilimanjar from "../../../images/nigeria/personal/kilimanjar.png";
import princeEbeanaSuperMarket from "../../../images/nigeria/personal/princeEbeanaSuperMarket.png";
import justRiteSuperstore from "../../../images/nigeria/personal/justRiteSuperstore.png";
import eSportaSalts from "../../../images/nigeria/personal/eSportaSalts.png";
import amalaSky from "../../../images/nigeria/personal/amalaSky.png";
import mobil from "../../../images/nigeria/personal/mobil.png";
import gigLogistics from "../../../images/nigeria/personal/gigLogistics.png";
import cubanaGroup from "../../../images/nigeria/personal/cubanaGroup.png";
import {LayoutCard, MerchantContainerDiv, MiddleContainer} from "./banking";
import MiddleHeaderImage from "../../../images/nigeria/personal-banking/middle-header.png";


const SalaryAdvance = ({data}) => {

    const businessTips = data.businessTips.nodes;
    const businesses = [papieesMeatro, thePlace, kilimanjar, princeEbeanaSuperMarket, justRiteSuperstore,
        eSportaSalts, amalaSky, mobil, gigLogistics, cubanaGroup];
    return (
        <Layout
        pageTitle={Nigeria_MetaTags.personalBanking.salaryAdvance.title}
        description={Nigeria_MetaTags.personalBanking.salaryAdvance.description}
            bankingType={'personal'}
        >
            <Helmet>
                <link rel="canonical" href="https://moniepoint.com/ng/personal/salary-advance" />
                <link rel="alternate" href="https://moniepoint.com/ng/personal/salary-advance" hreflang="x-default"/>
                <link rel="alternate" href="https://moniepoint.com/ng/personal/salary-advance" hreflang="en-ng"/>
                <script type="application/ld+json">
                    {`{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Moniepoint Nigeria",
  "description": "We are a financial services company based in Nigeria, offering a range of business banking services including business accounts, business loans, pos terminal machines, expense cards, and more.",
  "image": "https://moniepoint.com/static/MFB-667f089014bfae5d706fc620b07be2e0.png",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "12, Wole Ariyo Street.",
    "addressLocality": "Lekki Phase 1",
    "addressRegion": "Lagos",
    "postalCode": "106104",
    "addressCountry": "NG"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 6.44300,
    "longitude": 3.45908
  },
  "url": "https://moniepoint.com/ng/",
  "telephone": "+01 888 9990",
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "08:00",
    "closes": "17:00"
  },
  "sameAs": [
    "https://www.facebook.com/Moniepointng/",
    "https://instagram.com/moniepointng",
    "https://twitter.com/moniepointng",
    "https://www.linkedin.com/showcase/moniepoint/"
  ]
}`}


                </script>
            </Helmet>
            <div>

                <HeroContainer src={HeroImage} mobileSrc={HeroImageMobile}>
                    <div className="hero-mask"></div>
                    <div className="hero-content-wrapper">
                        <div className="hero-content">
                            <div className="award-badge">
                                <span>
                                    <Trans>salaryAdvance.hero_soon</Trans>
                                </span>
                            </div>
                            <h1 className="hero-title font-grotesk">
                                <Trans>salaryAdvance.hero_title</Trans>
                            </h1>
                            {/* used for SEO tags*/}
                            <h2 className={'d-none'}>
                                Payday Loans Salary Advance Loans
                            </h2>
                            <p className="hero-subtitle">
                                <Trans>salaryAdvance.hero_subtitle</Trans>
                            </p>
                <a href="https://app.adjust.com/13owp4gc" target="_blank" className="btn btn-primary">
                                <Trans>salaryAdvance.hero_cta</Trans>
                                <ArrowRightShortIcon size={24}/>
                            </a>
                        </div>
                    </div>
                </HeroContainer>
            </div>
            <UseContainer>
                <section className='use-container'>

                    <div className={'d-flex   features-wrapper position-relative w-100'}>
                        <div style={{display: "flex", flexDirection: "column", flexBasis: "55%"}}
                             className='use-container-inner '>
                            <div className={'d-flex flex-md-row flex-column upper-features'}>
                                <div className={'feature-names w-100'}>
                                    Access up to 100% of<br className={'d-none d-sm-block'}/> your salary in advance
                                </div>
                                <div className={'border-1 border-start d-none d-sm-block'}>

                                </div>
                                <div className={'feature-names w-100'}>
                                    Zero interests on<br className={'d-none d-sm-block'}/> advance repayment
                                </div>
                            </div>
                            <div className={'d-flex flex-row x-line'}>
                                <div className={'border-1 w-100 border-bottom'}>
                                </div>
                                <div className={''} style={{width: '30px'}}>
                                </div>
                                <div className={'border-1 w-100 border-bottom'}>
                                </div>
                            </div>
                            <div className={'d-flex flex-md-row flex-column upper-features'}>
                                <div className={'feature-names w-100'}>
                                    Request an advance <br className={'d-none d-md-block'}/> with ease
                                </div>
                                <div className={'border-1 border-start d-none d-sm-block'}>

                                </div>
                                <div className={'feature-names w-100'}>
                                    Auto repayment at the <br className={'d-none d-md-block'}/> end of the month
                                </div>
                            </div>

                        </div>
                        <div className={'image-div'}>
                            <img style={{width: "100%", minWidth: "50%"}} src={mobileVector} className='mobile-vector'/>
                        </div>
                    </div>
                </section>
            </UseContainer>

            <MerchantContainerDiv>
                <div className={'content d-flex flex-column'}>
                    <div className={'d-flex flex-column '}>
                        <div className={'pay-with-transfer'}>
                            Life <span className={'without'}> happens. </span><br/>
                            We get it.
                        </div>
                    </div>
                    <div className={'d-flex flex-md-row flex-sm-column layout-wrapper'}>
                        <LayoutCard icon={SafetyWidget}
                                    desc={'Request a salary advance and get it approved in less than 24hrs to attend to your emergencies'}
                                    title={'Stay ahead of emergencies'}
                        />
                        <LayoutCard icon={RequestWidget}
                                    desc={'Enjoy 0 interests when repaying your advance. You repay only what you requested.'}
                                    title={'Request as much as you need'}
                        />
                        <LayoutCard icon={RepaymentWidget}
                                    desc={'Get up to 100% of you salary in advance depending on your needs'}
                                    title={'No repayment interest'}
                        />
                    </div>
                </div>
            </MerchantContainerDiv>
            <MiddleContainer src={MiddleHeaderImage}>
                <div className="content-main">
                    <div className="content">
                        <div className="middle-header">
                            Be the <span className={'middle-header-million'}> first</span> to know when it &nbsp;
                            <br className={'d-none d-md-block'}/>
                            goes <span className={'middle-header-million'}>live</span>.
                        </div>
                        <div className={'join'}>
                            <a
                  href="https://app.adjust.com/13owp4gc"
                                target="_blank"
                                className="text-decoration-none"
                            >
                                <span className={'border-bottom border-2 border-primary pb-2'}>
                                    Open an account
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </MiddleContainer>


        </Layout>
    );
};

export default SalaryAdvance;

export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessTips:  allGraphCmsPost(
    sort: {fields: date, order: DESC}
    filter: {category: {categoryName: {eq: "Business Tips"}}}
  ) {
    nodes {
      slug
      title
      formattedDate
      coverImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 497px;
  max-height: 575px;
  position: relative;


  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .award-badge {
    display: flex;
    padding: 3px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    width: fit-content;
    background: var(--colors-primary-yellow-100, #FED75B);
    color: var(--colors-neutral-black-80, #333);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 141%; /* 19.74px */
    text-transform: uppercase;
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    background: url(${(props) => props.mobileSrc});
    height: 730px;
    background-size: cover;
    background-position: 70%;

    .hero-mask {
      background: linear-gradient(180deg,
      rgba(29, 29, 29, 0) 0%,
      rgba(29, 29, 29, 0.5) 79.14%);
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 32px;
        line-height: 35px;
      }

      .hero-subtitle {
        font-size: 17px;
        line-height: 27.37px;
      }
    }
  }
`;

const UseContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  background-image: url(${useCasesBG});
  background-repeat: no-repeat;
  background-position: right 10px top 140px;

  .features-wrapper {
    flex-direction: row;
  }

  @media (max-width: 1024px) {
    .features-wrapper {
      flex-direction: column;
    }
  }

  .features {
    display: flex;
    width: 702px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 96px 102px;
    flex-wrap: wrap;
  }

  .upper-features {
    gap: 51px
  }

  .x-line {
    gap: 51px
  }

  .feature-names {
    color: #000;
    font-family: "Founders Grotesk";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 32px */
  }

  .use-container-inner {
    gap: 48px;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    .upper-features {
      gap: 0;
    }

    .use-container-inner {
      gap: 0;
    }

    .x-line {
      display: none !important;
    }

    .feature-names {
      padding: 32px 0;
      border-bottom: 2px solid #CDDDFC;
      font-size: 24px;
    }
  }
  @media (max-width: 1024px) {
    background-image: none;
    margin: 0;
  }

  .use-container {
    display: flex;
    align-items: center;
    padding: 113px 0;
    margin: auto 100px auto 16%;
    @media (max-width: 1024px) {
      padding: 60px 4px;
      margin: auto 7%;
      display: block;
    }
    @media (min-width: 1024px) {
      .image-div {
        position: absolute;
        right: 0;
        top: -100px;
        z-index: 0;

        img {
          height: 439px;
        }
      }
    }
    @media (max-width: 1024px) {
      background-image: none;
      padding-bottom: 0;
      .image-div {
        padding-top: 48px;
        background-image: url(${useCasesBG});
        background-repeat: no-repeat;
        background-position: 32px 66px;
        background-size: contain;
        align-items: center;
        display: flex;
        text-align: center;
        width: 100%;
        justify-content: center;

        .mobile-vector {
          width: 256px !important;
        }
      }
    }


    .span-text-left {
      @media (max-width: 1440px) {
        font-size: 20px !important;
        padding-bottom: 20px !important;
        margin-right: 20px !important;
        line-height: 20px !important;
        width: 200px !important;
        font-weight: 500 !important;
      }
      @media (max-width: 992px) {
        font-size: 16px !important;
      }
    }

    .span-text-right {
      @media (max-width: 1440px) {
        font-size: 20px !important;
        padding-bottom: 20px !important;
        margin-left: 20px !important;
        width: 200px !important;
        line-height: 20px !important;
        font-weight: 500 !important;
      }
      @media (max-width: 992px) {
        font-size: 16px !important;
      }
    }


  }



`;
const ToolsContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    padding-top: 40px;
    margin: auto 16%;

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 48px;
      -webkit-letter-spacing: 0em;
      -moz-letter-spacing: 0em;
      -ms-letter-spacing: 0em;
      letter-spacing: 0em;
      text-align: start;
      margin-bottom: 32px;
    }

    // padding: 82px 150px 77px;
    @media only screen and (max-width: 1024px) {
      padding: 82px 60px 77px;
    }

    .bank {
      color: #0361F0;
      font-weight: 600;

    }

    .need-to-pay {
      font-family: inter;
      color: black;
      font-weight: 500;
      width: 55ch;
      line-height: 32px;
      font-size: 20px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .tools-list {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 16px;

      .tools-item {
        max-width: 247.28px;
        text-decoration: none;
        margin: auto;

        .tool-name {
          font-family: "Founders Grotesk";
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
          margin-top: 16px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background: white;
    .content {
      padding: 20px 20px 48px 20px !important;
      margin: auto !important;

      .tools-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .tools-item {
          max-width: 177px;
          padding: 24px 16px;
          border: 1px solid rgba(3, 87, 238, 0.05);
          border-radius: 8px;
          gap: 16px;
          filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

          .tool-name {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
const ToolsContainerTwo = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  padding-bottom: 74px;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 48px 150px 77px;
    @media only screen and (max-width: 1024px) {
      padding: 48px 60px 77px;
    }

    .bank {
      color: #0361F0
    }

    .need-to-pay {
      color: black;
      font-weight: 500;
      width: 55ch;
      line-height: 32px;
      font-size: 20px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 72px;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: start;
      //   width:10ch;
      margin-bottom: 32px;
    }

    .tools-container-two {
      font-family: "Founders Grotesk";
      font-size: 72px;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: start;
      width: 18ch;
      margin-bottom: 32px;
    }
  }

  .tools-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;

    .tools-item {
      max-width: 247.28px;
      text-decoration: none;
      margin: auto;

      .tool-name {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 16px;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  background: white;
  .content {
    padding: 115.73px 20px 32px;

    .title {
      font-size: 24px;
      line-height: 28.32px;
      margin-bottom: 32px;
    }

    .tools-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .tools-item {
        max-width: 177px;
        padding: 24px 16px;
        border: 1px solid rgba(3, 87, 238, 0.05);
        border-radius: 8px;
        gap: 16px;
        filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

        .tool-name {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
`;
const InstantDebitCardsContainer = styled.div`
  .beTheFirstSection {
    * {
      font-family: "Founders Grotesk";
    }

    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${vector});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

    .open-account-btn {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      color: #0361F0;
      padding: 0;
      padding-bottom: 8px;
      border-bottom: 2px solid #0361F0;
      border-radius: 0;
      cursor: pointer;
    }

    .title {
      font-size: 64px !important;
      line-height: 64px !important;
    }
  }

  .hover-animate:hover {
    opacity: 0.8;
    transform: translateY(-4px);
  }

  .wrapper-card {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 100px;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 0;
    }
    @media only screen and (max-width: 768px) {

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: white;
    }
  }

  .debit-heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 38.4px;
    font-family: Founders Grotesk;
  }

  .debit-content {
    font-family: inter;
    font-size: 16px;
    line-height: 25.6px;
    font-weight: 500;
    color: black;
  }

  .wrapper-card-support {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding-bottom: 32px;
  }

}

.debit-card {
  padding: 40px;
  height: 421px;
  width: 554px;
  border-radius: 16px;
  background: white;
  border: 1px solid #E5E7E9;
  box-shadow: -8px 20.25px 60px 0px rgba(3, 97, 240, 0.16);
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }

}

.security-card {
  padding: 40px;
  height: 412px;
  width: 554px;
  border-radius: 16px;
  background: white;
}

.debit-img {
  margin-bottom: 60px;

}

.debit-heading {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

.debit-content {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: black;
}

.banking-img {
  margin-bottom: 60px;
}

.banking-name {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

.banking-content {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: black;
}

.banking-need {
  width: 554px;
  height: 472px;
  border-radius: 16px;
  background: white;
  padding: 40px;
  margin-top: 100px;
  border: 1px solid #E5E7E9;
  box-shadow: -8px 20.25px 60px 0px rgba(3, 97, 240, 0.16);
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }
}

.protection-card {
  width: 554px;
  height: 428px;
  border-radius: 16px;
  background: white;
  padding: 40px;
}

.support-card {
  margin: auto;
  display: flex;
  width: 1140px;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 40px;
  background: white;
  border-radius: 16px;
  border: 1px solid rgba(229, 231, 233, 1);

}

}
//  background-color: rgba(3, 87, 238, 0.03);

.content {
  max-width: 1440px;
  margin: auto;
  padding: 48px 150px 77px;
  @media only screen and (max-width: 1024px) {
    padding: 48px 60px 77px;
  }

  .bank {
    color: #0361F0
  }

  .need-to-pay {
    color: black;
    font-weight: 500;
    width: 55ch;
    line-height: 32px;
    font-size: 20px;
  }

  .title {
    font-family: "Founders Grotesk";
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: start;
    //   width:10ch;
    margin-bottom: 32px;
  }

}

.tools-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;

  .tools-item {
    max-width: 247.28px;
    text-decoration: none;
    margin: auto;

    .tool-name {
      font-family: "Founders Grotesk";
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 16px;
      color: rgba(0, 0, 0, 1);
    }
  }
}

}

@media only screen and (max-width: 768px) {
  .wrapper-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .content {
      padding: 115.73px 20px 32px;

      .title {
        font-size: 24px;
        line-height: 28.32px;
        margin-bottom: 32px;
      }

      .tools-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .tools-item {
          max-width: 177px;
          padding: 24px 16px;
          border: 1px solid rgba(3, 87, 238, 0.05);
          border-radius: 8px;
          gap: 16px;
          filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

          .tool-name {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
const BusinessContainer = styled.div`
  background: rgba(242, 252, 255, 0.5);

  .fast-payment-container {
    margin: 0 auto;
    max-width: 968px;

  }

  .fast-payment-heading {
    font-weight: 500;
    line-height: 66px;
    text-align: center;
    font-size: 64px;
    padding: 48px 0;
  }

  .fast-payment-imgs {
    display: flex;
    gap: 21px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .fast-payment-btn {
    display: flex;
    justify-content: center;
    padding: 48px 0;
  }

  .fast-payment-btn-clr {

    padding: 12px 24px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    border-radius: 8px;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    :hover {
      opacity: 0.8;
      transform: translateY(-4px);
      color: white;
      background-color: rgba(0, 0, 0, 1);
      border-color: rgba(0, 0, 0, 1);
    }

    svg {
      path {
        fill: white;
      }
    }

  }



`;
const EaseContainer = styled.div`
  background: rgba(2, 56, 139, 1);
  padding-top: 118px;
  padding-bottom: 118px;
  padding-left: 204px;
  padding-right: 204px;

  .ease-heading {
    text-align: center;
    font-size: 64px;
    line-height: 66px;
    font-weight: 600;
    margin-bottom: 60px;
    color: white;
    padding-top: 118px;
  }

  .ease-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 60px;
    color: white;
  }

  .ease-btn {
    justify-content: center;
    align-items: center;
    display: flex

  }

  .ease-btn-clr {

    padding: 12px 24px;
    background-color: rgba(254, 215, 91, 1);
    color: black;
    border-radius: 8px;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    :hover {
      opacity: 0.8;
      transform: translateY(-4px);
      color: black;
      background-color: rgba(254, 215, 91, 1);
      border-color: rgba(254, 215, 91, 1);
    }

    svg {
      path {
        fill: black;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background: rgba(2, 56, 139, 1);
    padding-top: 118px;
    padding-bottom: 118px;
    padding-left: 150px;
    padding-right: 150px;
    .ease-content {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      gap: 24px;
      font-weight: 500;
      line-height: 24px;
      font-size: 18px;
      margin-bottom: 40px;
      color: white;
    }

    .ease-heading {
      text-align: start;
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
      margin-bottom: 60px;
      color: white;
      padding-top: 118px;
    }

    @media only screen and (max-width: 480px) {
      padding-top: 118px;
      padding-bottom: 118px;
      padding-left: 150px;
      padding-right: 150px;
      .ease-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        font-weight: 500;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 30px;
        color: white;
      }

      .ease-heading {
        text-align: start;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 60px;
        color: white;
        padding-top: 118px;
      }
    }
`;


const LearnAllContainer = styled.section`
  background-color: white;
  position: relative;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px 72px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px 72px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 40px;
      font-weight: 500;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left;
    }

    .swiper-section {
      margin-top: 32px;
    }

    .learn-all-card {
      width: 348px;
      text-decoration: none;

      .card-img {
        overflow: hidden;;
        border-radius: 8px;
        margin-bottom: 24px;

        img {
          width: 348px;
          height: 187px;
          object-fit: cover;
        }
      }

      .card-title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1)
      }

      .card-author {
        font-family: "Founders Grotesk";
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1)
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 57px 20px 48px;

      .title {
        font-size: 32px;
        line-height: 38px;
      }

      .learn-all-card {
        width: 304px;

        .card-title {
          font-size: 16px;
          line-height: 19px;
        }

        .card-author {
          display: none;
        }
      }
    }
  }
`;

